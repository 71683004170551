import { ToastService } from './../theme/shared/components/toast/toast.service';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // apiKey = 'jizTLnG3.8P7jsiCnGCUpQywdkptxfxbnErIVfhwGFVY8PugywxIBxvmTZx348OLfdVHsPe7P';
  apiKey = 'NynuPeXw.ZRIQJSDMn7ZNMal36FHfR87cWKgMEr0GxR37VA7Y93ag7lUKeGddhfOj8xGxw6TI';
  currentUser: any;
  items: any;
  liveToken: any =  this.getToken();
  httpHeaders = new HttpHeaders({
    'X-API-KEY': environment.apiKey,
    // 'API-KEY': this.apiKey,
    Authorization: 'eCampus ' + localStorage.getItem('token'),
    // Accept: 'application/json'
  });
  HttpHeadersBeforeLogin = new HttpHeaders({
    'X-API-KEY': environment.apiKey,
    // 'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
    // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    // Referer: '52.66.185.61'
  });
  public userDetails = new BehaviorSubject<any>([]);
  public alerts = new BehaviorSubject<any>([]);
  public currentModule = new BehaviorSubject<any>(null);
  public currentNavigations = new BehaviorSubject<any>([]);
  public editDatas = new BehaviorSubject<any>([]);
  readonly rootUrl = environment.apiUrl;
  position = 'top-center';
  constructor(
    private router: Router,
    private httpcli: HttpClient,
    public toastEvent: ToastService
    // private toastyService: ToastyService,
  ) { }
  get userDetails$(): any {
    return this.userDetails.asObservable();
  }
  get alerts$(): any {
    return this.alerts.asObservable();
  }
  get currentNavigations$(): any {
    return this.currentNavigations.asObservable();
  }
  setAlerts(alertData): any {
    this.alerts.next(alertData);
  }
  get currentModule$(): any {
    return this.currentModule.asObservable();
  }
  get editDatas$(): any {
    return this.editDatas.asObservable();
  }
  setCurrentModule(moduleName): any {
    return this.currentModule.next(moduleName);
  }
  setNavigations(navigationItems): any {
    return this.currentNavigations.next(navigationItems);
  }
  setEditDatas(data): any {
    return this.editDatas.next(data);
  }
  getMethod($method: string, paramss: any = null): any {
    return this.httpcli.get(this.rootUrl + $method, { headers: this.httpHeaders, params: paramss})
    .pipe(map((response: Response) => {
      const responseData: any = response;
      if ((responseData.status === false && responseData.code === 401) ||
      (responseData.message && responseData.message === 'Given token not valid for any token type')) {
        this.logout();
        this.showSnackbar('Your session has been expired, Please LogIn', 'error');
        this.router.navigate(['/']);
      }
      return responseData;
    }, error => {
      console.log(error);
    }
    ));
  }
  getMethodPlain($method: string, paramss: any = null): any {
    return this.httpcli.get(this.rootUrl + $method, { headers: this.HttpHeadersBeforeLogin, params: paramss})
    .pipe(map((response: Response) => {
      const responseData: any = response;
      if ((responseData.status === false && responseData.code === 401) ||
      (responseData.message && responseData.message === 'Given token not valid for any token type')) {
        this.logout();
        this.showSnackbar('Your session has been expired, Please LogIn', 'error');
        this.router.navigate(['/']);
      }
      return responseData;
    }, error => {
      console.log(error);
    }
    ));
  }

  putMethod($method: string, params: any= null): any {
    return this.httpcli.put(this.rootUrl + $method, params, { headers: this.httpHeaders })
    .pipe(map((response: Response) => {
      const responseData: any = response;
      if ((responseData.status === false && responseData.code === 401) ||
      (responseData.message && responseData.message === 'Given token not valid for any token type')) {
        this.logout();
        this.showSnackbar('Your session has been expired, Please LogIn', 'error');
        this.router.navigate(['/']);
      }
      return responseData;
    }
    ));
  }
  putMethodPlain($method: string, params: any= null): any {
    return this.httpcli.put(this.rootUrl + $method, params, { headers: this.HttpHeadersBeforeLogin })
    .pipe(map((response: Response) => {
      const responseData: any = response;
      if ((responseData.status === false && responseData.code === 401) ||
      (responseData.message && responseData.message === 'Given token not valid for any token type')) {
        this.logout();
        this.showSnackbar('Your session has been expired, Please LogIn', 'error');
        this.router.navigate(['/']);
      }
      return responseData;
    }
    ));
  }
  patchMethod($method: string, params: any= null): any {
    return this.httpcli.patch(this.rootUrl + $method, params, { headers: this.httpHeaders })
    .pipe(map((response: Response) => {
      const responseData: any = response;
      if ((responseData.status === false && responseData.code === 401) ||
      (responseData.message && responseData.message === 'Given token not valid for any token type')) {
        this.logout();
        this.showSnackbar('Your session has been expired, Please LogIn', 'error');
        this.router.navigate(['/']);
      }
      return responseData;
    }
    ));
  }
  deleteMethod($method: string, body: any= null): any {
    let options = {
    headers: this.httpHeaders,
    body
  };
    return this.httpcli.delete(this.rootUrl + $method,options)
    .pipe(map((response: Response) => {
      const responseData: any = response;
      if ((responseData.status === false && responseData.code === 401) ||
      (responseData.message && responseData.message === 'Given token not valid for any token type')) {
        this.logout();
        this.showSnackbar('Your session has been expired, Please LogIn', 'error');
        this.router.navigate(['/']);
      }
      return responseData;
    }
    ));
  }
  // deleteMethod($method: string, params: any= null): any {
  //   return this.httpcli.delete(this.rootUrl + $method, { headers: this.httpHeaders })
  //   .pipe(map((response: Response) => {
  //     const responseData: any = response;
  //     if (responseData.status === false && responseData.message === 'Invalid Access. Please check with your web administrator.') {
  //       this.logout();
  //       this.showSnackbar('Your session has been expired / Someone logged in other system, Please LogIn', 'error');
  //       this.router.navigate(['/']);
  //     }
  //     return responseData;
  //   }
  //   ));
  // }
  postFormData($method: string, params: any= null): any {
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.liveToken,
    });
    return this.httpcli.post(this.rootUrl + $method, params, {headers: httpHeaders})
    .pipe(map((response: Response) => {
      const responseData: any = response;
      if ((responseData.status === false && responseData.code === 401) ||
      (responseData.message && responseData.message === 'Given token not valid for any token type')) {
        this.logout();
        this.showSnackbar('Your session has been expired, Please LogIn', 'error');
        this.router.navigate(['/']);
      }
      return responseData;
    }
    ));
  }

  postMethod($method: string, params: any= null): any {
    return this.httpcli.post(this.rootUrl + $method, params, {headers: this.httpHeaders})
    .pipe(map((response: Response) => {
      const responseData: any = response;
      if ((responseData.status === false && responseData.code === 401) ||
      (responseData.message && responseData.message === 'Given token not valid for any token type')) {
        this.logout();
        this.showSnackbar('Your session has been expired, Please LogIn', 'error');
        this.router.navigate(['/']);
      }
      return responseData;
    }
    ));
  }

  postMethodPlain($method: string, params: any= null): any {
    return this.httpcli.post(this.rootUrl + $method, params, {headers: this.HttpHeadersBeforeLogin})
    .pipe(map((response: Response) => {
      const responseData: any = response;
      if (responseData.status === false && responseData.message === 'Invalid Access. Please check with your web administrator.') {
        this.logout();
        this.showSnackbar('Your session has been expired / Someone logged in other system, Please LogIn', 'error');
        this.router.navigate(['/']);
      }
      return responseData;
    }
    ));
  }

  login(credentials): any {
    const route: any = '/authentication/obtain-access-token/';
    return this.httpcli.post(this.rootUrl + route, credentials, {headers: this.HttpHeadersBeforeLogin})
    .pipe(map((response: Response) => {
      this.items = response;
      if (this.items.status === false && this.items.code === 401) {
        this.logout();
        this.showSnackbar('Your session has been expired, Please LogIn', 'error');
        this.router.navigate(['/']);
      }
      return this.items;
    }
    ));
  }
  logout(): any {
    this.showSnackbar('Logout Successful', 'success');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/auth/signin';
  }
  getToken(): any {
    const token = localStorage.getItem('token');
    if (token) {
      return token;
    }
    return false;
  }
  showSnackbar(msg, status = null): void {
    if (status === 'error') {
      status = 'danger';
    }
    const alertData: any = [];
    alertData.type = status;
    alertData.message = msg;
    alertData.dismiss = true;
    this.setAlerts(alertData);
  }
  errorMessage(thiss, error: any): void {
    if (thiss.spinner) {
      thiss.spinner = false;
    }
    if (error.status && error.status === 504) {
      this.showSnackbar(`${error.status} : Connection lost with in the server, Script has run successfully`, 'warning');
    } else if (error.status && error.status ===  401) {
      this.showSnackbar(`Session timed out`, 'warning');
      // this.logout();
    } else if (error && error.status && error.status === 0) {
      this.nostatusCode();
    } else {
      this.nostatusCode();
      if (thiss.spinner) {
        thiss.spinner = false;
      }
    }
  }
  nostatusCode(): any {
    this.showSnackbar(`Server Error, Please contact admin`, 'error');
  }
  validateMobile(evt): any {
    const ASCIICode  = (evt.which) ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      return false;
    }
    return true;
  }
  addToast(options): any {
  }
  getToastOptions(options): any {
  }
  getUserDatas(): any {
    return this.userDetails$;
  }
  setUserInfo(data): any {
    this.userDetails.next(data);
  }
  getInfo(): any {
    return this.userDetails$;
  }
  validateRole($rolename = null, $rolename2 = null): any {
    const roles = this.userDetails.value.data.roles;
    if (roles[roles.indexOf($rolename2)]) {
      return true;
    } else if (roles[roles.indexOf($rolename)]) {
      return true;
    }
    return false;
  }
  downloadFile(src): void {
    if (src) {
      window.open(src, '_blank');
    }
  }
  checkIsVaild(imgSrc, ext): any {
    const extensionXl = imgSrc.includes('.xls');
    const extensionXlsx = imgSrc.includes('.xlsx');
    const extensionPdf = imgSrc.includes('.pdf');
    if (ext === 'img') {
      if (!extensionPdf && !extensionXl && !extensionXlsx) {
        return true;
      }
      return false;
    } else {
      if (ext === 'xls') {
        if (extensionXl || extensionXlsx) {
          return true;
        }
      } else if (ext === 'pdf') {
        if (extensionPdf) {
          return true;
        }
      }
    }
    return false;
  }
  defaultNavigationItems(): any {
    const navigationItems: any = [
      {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
          {
            id: 'menu-dashboard',
            title: 'Main Dashboard',
            type: 'item',
            url: '/dashboard/home',
            icon: 'feather icon-home',
            classes: 'nav-item',
            breadcrumbs: true,
          },
          {
            id: 'menu-institution',
            title: 'Institution Profile',
            type: 'item',
            url: '/institution/profile',
            icon: 'feather icon-layers',
            classes: 'nav-item',
            breadcrumbs: true,
          },
        ]
      }
    ];
    return navigationItems;
  }
  getNavigationItemsAttendance(currentModule): any {
    const navigationItems: any = [{
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'feather icon-monitor',
      children: [
        {
          id: 'menu-dashboard',
          title: 'Main Dashboard',
          type: 'item',
          url: '/dashboard/home',
          icon: 'feather icon-home',
          classes: 'nav-item',
          breadcrumbs: true,
        },
        {
          id: 'menu-institution',
          title: 'Institution Profile',
          type: 'item',
          url: '/institution/profile',
          icon: 'feather icon-layers',
          classes: 'nav-item',
          breadcrumbs: true,
        },
      ],
    },
    {
      id: 'module-root',
      title: currentModule,
      type: 'group',
      icon: 'feather icon-monitor',
      children: [
        {
          id: 'menu-dashs',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-users',
          url: `/${currentModule}/dashboard`,
          breadcrumbs: true,
          classes: 'nav-item',
        },
        {
          id: 'menu-masters',
          title: 'Masters',
          type: 'item',
          icon: 'feather icon-users',
          url: `/${currentModule}/masters`,
          breadcrumbs: true,
          classes: 'nav-item',
        },
        {
          id: 'menu-settings',
          title: 'Settings',
          type: 'item',
          icon: 'feather icon-layers',
          url: `/${currentModule}/settings`,
          breadcrumbs: true,
          classes: 'nav-item',
        },
        {
          id: 'menu-actions',
          title: 'Actions',
          type: 'item',
          icon: 'feather icon-gitlab',
          url: `/${currentModule}/actions`,
          breadcrumbs: true,
          classes: 'nav-item',
        },
        {
          id: 'menu-repoerts',
          title: 'Reports',
          type: 'item',
          icon: 'feather icon-pie-chart',
          url: `/${currentModule}/reports`,
          breadcrumbs: true,
          classes: 'nav-item'
        },
      ]
    }];
    return navigationItems;
  }
  getNavigationItems(currentModule): any {
    const navigationItems: any = [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'feather icon-monitor',
      children: [
        {
          id: 'menu-dashboard',
          title: 'Main Dashboard',
          type: 'item',
          url: '/dashboard/home',
          icon: 'feather icon-home',
          classes: 'nav-item',
          breadcrumbs: true,
        },
        {
          id: 'menu-institution',
          title: 'Institution Profile',
          type: 'item',
          url: '/institution/profile',
          icon: 'feather icon-layers',
          classes: 'nav-item',
          breadcrumbs: true,
        },
      ],
    },
    {
      id: 'module-root',
      title: currentModule,
      type: 'group',
      icon: 'feather icon-monitor',
      children: [
        {
          id: 'menu-dashs',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-users',
          url: `/${currentModule}/dashboard`,
          breadcrumbs: true,
          classes: 'nav-item',
        },
        {
          id: 'menu-masters',
          title: 'Masters',
          type: 'item',
          icon: 'feather icon-users',
          url: `/${currentModule}/masters`,
          breadcrumbs: true,
          classes: 'nav-item',
        },
        {
          id: 'menu-settings',
          title: 'Settings',
          type: 'item',
          icon: 'feather icon-layers',
          url: `/${currentModule}/settings`,
          breadcrumbs: true,
          classes: 'nav-item',
        },
        {
          id: 'menu-actions',
          title: 'Actions',
          type: 'item',
          icon: 'feather icon-gitlab',
          url: `/${currentModule}/actions`,
          breadcrumbs: true,
          classes: 'nav-item',
        },
        {
          id: 'menu-repoerts',
          title: 'Reports',
          type: 'item',
          icon: 'feather icon-pie-chart',
          url: `/${currentModule}/reports`,
          breadcrumbs: true,
          classes: 'nav-item'
        },
        //   ]
        // },
      ]
    }];
    return navigationItems;
  }
  getNavigationItemsStudent(currentModule): any {
    const navigationItems: any = [
      {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
          {
            id: 'menu-dashboard',
            title: 'Main Dashboard',
            type: 'item',
            url: '/dashboard/home',
            icon: 'feather icon-home',
            classes: 'nav-item',
            breadcrumbs: true,
          },
          {
            id: 'menu-institution',
            title: 'Institution Profile',
            type: 'item',
            url: '/institution/profile',
            icon: 'feather icon-layers',
            classes: 'nav-item',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'module-root',
        title: 'Student',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
          {
            id: 'menu-dashs',
            title: 'Dashboard',
            type: 'item',
            icon: 'feather icon-users',
            url: `/${currentModule}/dashboard`,
            breadcrumbs: true,
            classes: 'nav-item',
          },
          {
            id: 'menu-masters',
            title: 'Masters',
            type: 'collapse',
            icon: 'feather icon-users',
            children: [
              {
                id: 'add-academy',
                title: 'Add Academic Year',
                type: 'item',
                url: `/${currentModule}/academy`,
                breadcrumbs: true,
              },
              {
                id: 'add-existing-student',
                title: 'Add Existing Student',
                type: 'item',
                url: `/${currentModule}/addexistingstudent`,
                breadcrumbs: true,
              },
              {
                id: 'menu-master-prof',
                title: 'Profile',
                type: 'item',
                url: `/${currentModule}/profile`,
                breadcrumbs: true,
              },
              {
                id: 'menu-master-caste-category',
                title: 'Caste Category',
                type: 'item',
                url: `/${currentModule}/caste-category`,
                breadcrumbs: true,
              },
              {
                id: 'menu-master-caste',
                title: 'Caste',
                type: 'item',
                url: `/${currentModule}/caste`,
                breadcrumbs: true,
              },
              {
                id: 'menu-master-gender',
                title: 'Gender',
                type: 'item',
                url: `/${currentModule}/gender`,
                breadcrumbs: true,
              },
              {
                id: 'menu-master-quota',
                title: 'Quota',
                type: 'item',
                url: `/${currentModule}/quota`,
                breadcrumbs: true,
              },
              {
                id: 'menu-master-religion',
                title: 'Religion',
                type: 'item',
                url: `/${currentModule}/religion`,
                breadcrumbs: true,
              },
              {
                id: 'menu-master-mother-tongue',
                title: 'Mother Tongue',
                type: 'item',
                url: `/${currentModule}/mother-tongue`,
                breadcrumbs: true,
              },
            ],
          },
          {
            id: 'menu-settings',
            title: 'Settings',
            type: 'collapse',
            icon: 'feather icon-layers',
            url: `/${currentModule}/settings`,
            // breadcrumbs: true,
            // classes: 'nav-item',
            children: [
              {
                id: 'menu-student-import',
                title: 'Import',
                type: 'item',
                url: `/${currentModule}/import`,
                breadcrumbs: true,
              },
              {
                id: 'menu-student-export',
                title: 'Export',
                type: 'item',
                url: `/${currentModule}/export`,
                breadcrumbs: true,
              },
              {
                id: 'menu-student-deactivate',
                title: 'Activate/Deactivate Student',
                type: 'item',
                url: `/${currentModule}/activate-deactivate`,
                breadcrumbs: true,
              },
            ],
          },
          {
            id: 'menu-actions',
            title: 'Actions',
            type: 'item',
            icon: 'feather icon-gitlab',
            url: `/${currentModule}/actions`,
            breadcrumbs: true,
            classes: 'nav-item',
          },
          {
            id: 'menu-repoerts',
            title: 'Reports',
            type: 'collapse',
            icon: 'feather icon-pie-chart',
            // url: `/${currentModule}/reports`,
            breadcrumbs: true,
            classes: 'nav-item',
            children: [
              {
                id: 'menu-reports-students-list',
                title: 'Classwise Students List',
                type: 'item',
                url: `/${currentModule}/classwise-students-list`,
                breadcrumbs: true,
              },
            ],
          },
        ],
      }
    ];
    return navigationItems;
  }
  getNavigationItemsInstitution(): any {
    const navigationItems: any = [
      {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
          {
            id: 'menu-dashboard',
            title: 'Main Dashboard',
            type: 'item',
            url: '/dashboard/home',
            icon: 'feather icon-home',
            classes: 'nav-item',
            breadcrumbs: true,
          },
          {
            id: 'menu-institution',
            title: 'Institution Profile',
            type: 'item',
            url: '/institution/profile',
            icon: 'feather icon-layers',
            classes: 'nav-item',
            breadcrumbs: true,
          },
        ],
      },
    ];
    return navigationItems;
  }
  getNavigationItemsFees(currentModule): any {
    const navigationItems: any = [
      {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
          {
            id: 'menu-dashboard',
            title: 'Main Dashboard',
            type: 'item',
            url: '/dashboard/home',
            icon: 'feather icon-home',
            classes: 'nav-item',
            breadcrumbs: true,
          },
          {
            id: 'menu-institution',
            title: 'Institution Profile',
            type: 'item',
            url: '/institution/profile',
            icon: 'feather icon-layers',
            classes: 'nav-item',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'module-root',
        title: 'Fees',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
          {
            id: 'menu-fees-dashs',
            title: 'Dashboard',
            type: 'item',
            icon: 'feather icon-users',
            url: `/${currentModule}/dashboard`,
            breadcrumbs: true,
            classes: 'nav-item',
          },
          {
            id: 'menu-masters',
            title: 'Masters',
            type: 'collapse',
            icon: 'feather icon-users',
            children: [
              {
                id: 'menu-fee-category',
                title: 'Fee Category',
                type: 'item',
                url: `/${currentModule}/category`,
                breadcrumbs: true,
              },
              {
                id: 'menu-fee-type',
                title: 'Fee Type',
                type: 'item',
                url: `/${currentModule}/type`,
                breadcrumbs: true,
              },
              {
                id: 'menu-fee-to-class',
                title: 'Fee to Class',
                type: 'item',
                url: `/${currentModule}/fee-to-class`,
                breadcrumbs: true,
              },
              {
                id: 'menu-fee-to-student',
                title: 'Fee to Student',
                type: 'item',
                url: `/${currentModule}/fee-to-student`,
                breadcrumbs: true,
              },
              {
                id: 'menu-payment-mode',
                title: 'Payment Mode',
                type: 'item',
                url: `/${currentModule}/payment-mode`,
                breadcrumbs: true,
              }
            ],
          },
          {
            id: 'menu-settings',
            title: 'Settings',
            type: 'item',
            icon: 'feather icon-layers',
            url: `/${currentModule}/settings`,
            breadcrumbs: true,
            classes: 'nav-item',
          },
          {
            id: 'menu-actions',
            title: 'Actions',
            type: 'collapse',
            icon: 'feather icon-gitlab',
            breadcrumbs: true,
            // classes: 'nav-item',
            children: [
              {
                id: 'menu-fee-collection',
                title: 'Fee Collection',
                type: 'item',
                url: `/${currentModule}/actions`,
                breadcrumbs: true,
              },
              {
                id: 'menu-fee-concession',
                title: 'Assign Fee Concession',
                type: 'item',
                url: `/${currentModule}/fee-concession`,
                breadcrumbs: true,
              },
              {
                id: 'menu-fee-old',
                title: 'Assign Old Fee',
                type: 'item',
                url: `/${currentModule}/old-fee`,
                breadcrumbs: true,
              },
            ]
          },
          {
            id: 'menu-repoerts',
            title: 'Reports',
            type: 'collapse',
            icon: 'feather icon-pie-chart',
            // url: `/${currentModule}/reports`,
            breadcrumbs: true,
            classes: 'nav-item',
            children: [
              {
                id: 'fee-report-daily',
                title: 'Daily Fee Collections',
                type: 'item',
                url: `/${currentModule}/report-daily-collection`,
                breadcrumbs: true,
              },
              {
                id: 'fee-report-coll',
                title: 'Fee Collections',
                type: 'item',
                url: `/${currentModule}/report-fee-collection`,
                breadcrumbs: true,
              },
              {
                id: 'fee-report-print',
                title: 'Re Print Fee Collection',
                type: 'item',
                url: `/${currentModule}/reprint-fee`,
                breadcrumbs: true,
              },
              {
                id: 'fee-bill-to-bill',
                title: 'Bill to Bill',
                type: 'item',
                url: `/${currentModule}/report-bill-to-fee`,
                breadcrumbs: true,
              },
            ],
          },
        ],
      }
    ];
    return navigationItems;
  }
  getNavigationItemsMedia(currentModule): any {
    const navigationItems: any = [
      {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
          {
            id: 'menu-dashboard',
            title: 'Main Dashboard',
            type: 'item',
            url: '/dashboard/home',
            icon: 'feather icon-home',
            classes: 'nav-item',
            breadcrumbs: true,
          },
          {
            id: 'menu-institution',
            title: 'Institution Profile',
            type: 'item',
            url: '/institution/profile',
            icon: 'feather icon-layers',
            classes: 'nav-item',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'module-root',
        title: 'Media',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
          {
            id: `menu-${currentModule}-dashs`,
            title: 'Dashboard',
            type: 'item',
            icon: 'feather icon-users',
            url: `/${currentModule}/dashboard`,
            breadcrumbs: true,
            classes: 'nav-item',
          },
          {
            id: 'menu-masters',
            title: 'Masters',
            type: 'collapse',
            icon: 'feather icon-users',
            children: [
              // {
              //   id: 'menu-media-sms-template',
              //   title: 'SMS Template',
              //   type: 'item',
              //   url: `/${currentModule}/sms-template`,
              //   breadcrumbs: true,
              // },
              {
                id: 'menu-media-sms-group',
                title: 'SMS Group',
                type: 'item',
                url: `/${currentModule}/sms-group`,
                breadcrumbs: true,
              },
            ],
          },
          {
            id: 'menu-settings',
            title: 'Settings',
            type: 'item',
            icon: 'feather icon-layers',
            url: `/${currentModule}/settings`,
            breadcrumbs: true,
            classes: 'nav-item',
          },
          {
            id: 'menu-actions',
            title: 'Actions',
            type: 'collapse',
            icon: 'feather icon-gitlab',
            breadcrumbs: true,
            // classes: 'nav-item',
            children: [
              {
                id: 'menu-action-sms',
                title: 'Send SMS',
                type: 'item',
                url: `/${currentModule}/sms`,
                breadcrumbs: true,
              },
              {
                id: 'menu-action-sms-numbers',
                title: 'Send SMS By Mobile Numbers',
                type: 'item',
                url: `/${currentModule}/sms-mobile-numbers`,
                breadcrumbs: true,
              },
              {
                id: 'menu-action-sms-group',
                title: 'Send SMS By Group',
                type: 'item',
                url: `/${currentModule}/smsgroup`,
                breadcrumbs: true,
              },
              {
                id: 'menu-action-whatsapp',
                title: 'Whatsapp',
                type: 'item',
                url: `/${currentModule}/whatsapp`,
                breadcrumbs: true,
              },
              {
                id: 'menu-action-voice',
                title: 'Voice',
                type: 'item',
                url: `/${currentModule}/voice`,
                breadcrumbs: true,
              },
              {
                id: 'menu-action-video',
                title: 'Video',
                type: 'item',
                url: `/${currentModule}/video`,
                breadcrumbs: true,
              },
            ]
          },
          {
            id: 'menu-repoerts',
            title: 'Reports',
            type: 'item',
            icon: 'feather icon-pie-chart',
            url: `/${currentModule}/reports`,
            breadcrumbs: true,
            classes: 'nav-item',
            // children: [
            //   {
            //     id: 'fee-report-daily',
            //     title: 'Daily Fee Collections',
            //     type: 'item',
            //     url: `/${currentModule}/report-daily-collection`,
            //     breadcrumbs: true,
            //   },
            //   {
            //     id: 'fee-report-print',
            //     title: 'Re Print Fee Collection',
            //     type: 'item',
            //     url: `/${currentModule}/reprint-fee`,
            //     breadcrumbs: true,
            //   },
            // ],
          },
        ],
      }
    ];
    return navigationItems;
  }
  getNavigationItemsEmployee(currentModule): any {
    const navigationItems: any = [
      {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
          {
            id: 'menu-dashboard',
            title: 'Main Dashboard',
            type: 'item',
            url: '/dashboard/home',
            icon: 'feather icon-home',
            classes: 'nav-item',
            breadcrumbs: true,
          },
          {
            id: 'menu-institution',
            title: 'Institution Profile',
            type: 'item',
            url: '/institution/profile',
            icon: 'feather icon-layers',
            classes: 'nav-item',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'module-root',
        title: 'Employee',
        type: 'group',
        icon: 'feather icon-monitor',
        children: [
          {
            id: 'menu-dashboard',
            title: 'Dashboard',
            type: 'item',
            url: `/${currentModule}/dashboard`,
            icon: 'feather icon-home',
            classes: 'nav-item',
            // breadcrumbs: false
          },
          {
            id: 'menu-masters',
            title: 'Masters',
            type: 'collapse',
            icon: 'feather icon-users',
            children: [
              {
                id: 'menu-emp-master-prof',
                title: 'Profile',
                type: 'item',
                url: `/${currentModule}/profile`,
                breadcrumbs: false,
              },
              {
                id: 'menu-emp-master-dep',
                title: 'Department',
                type: 'item',
                url: `/${currentModule}/department`,
                breadcrumbs: false,
              },
              {
                id: 'menu-emp-master-des',
                title: 'Designation',
                type: 'item',
                url: `/${currentModule}/designation`,
                breadcrumbs: false,
              },
            ],
          },
          {
            id: 'menu-settings',
            title: 'Settings',
            type: 'item',
            icon: 'feather icon-layers',
            url: `/${currentModule}/settings`,
            breadcrumbs: false,
            classes: 'nav-item',
          },
          {
            id: 'menu-actions',
            title: 'Actions',
            type: 'collapse',
            icon: 'feather icon-gitlab',
            breadcrumbs: false,
            classes: 'nav-item',
            children: [
              {
                id: 'menu-emp-act-attendance',
                title: 'Attendance',
                type: 'item',
                url: `/${currentModule}/attendance`,
                breadcrumbs: false,
              },
            ],
          },
          {
            id: 'menu-repoerts',
            title: 'Reports',
            type: 'item',
            icon: 'feather icon-pie-chart',
            url: `/${currentModule}/reports`,
            breadcrumbs: false,
            classes: 'nav-item',
          },
        ],
      },
    ];
    return navigationItems;
  }
  setModule(module, route = null): any {
    localStorage.setItem('currentModule', module);
    this.setCurrentModule(module);
    let navigationItems: any = {};
    if (module === 'student') {
      navigationItems = this.getNavigationItemsStudent(module);
    } else if (module === 'institution') {
      navigationItems = this.getNavigationItemsInstitution();
    } else if (module === 'fees') {
      navigationItems = this.getNavigationItemsFees(module);
    } else if (module === 'media') {
      navigationItems = this.getNavigationItemsMedia(module);
    } else if (module === 'employee') {
      navigationItems = this.getNavigationItemsEmployee(module);
    } else {
      navigationItems = this.getNavigationItems(module);
    }
    this.setNavigations(navigationItems);
    if (route) {
      this.router.navigate([`${module}/${route}`]);
    }
  }
  patch(id, params, route): any {
    this.patchMethod(route + id + '/', params).subscribe(
      res => {
        if (res.status === true) {
          this.showSnackbar(res.message, 'success');
        } else {
          this.showSnackbar(res.message, 'error');
        }
      }, error => {
        this.showSnackbar(error.message, 'error');
      }
    );
  }
  delete(id, route): any {
    this.deleteMethod(route + id + '/', null).subscribe(
      res => {
        if (res.status === true) {
          this.showSnackbar(res.message, 'success');
        } else {
          this.showSnackbar(res.message, 'error');
        }
      }, error => {
        this.showSnackbar(error.message, 'error');
      }
    );
  }
}

